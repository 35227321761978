/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Katalog"}>
        <SiteHeader />

        <Column className="pb--50 pl--0 pt--80" name={"uvod"}>
          
          <ColumnWrap className="column__flex el--1 pb--40 pl--40 pr--40 pt--60 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--center mt--10 pl--0 pr--0">
              
              <Title className="title-box fs--62 w--300 ls--002 lh--14" style={{"maxWidth":1082}} content={"Katalog"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" name={"zrf8vqibb0m"}>
          
          <ColumnWrap className="column__flex --left el--3 mb--80 flex--center" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 mt--30" anim={"2"} animS={"2"}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box lh--16 mt--20 pl--0 pr--0" content={"Komplet mebli wypoczynkowych ELEANOR — 4999 zł"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--02 pl--0 pr--0" content={"od 4 600,— Kč"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"katalog-produktów"} style={{"backgroundColor":"var(--color-custom-2)"}} border={null} layout={"l24"}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right mt--0" content={"<span style='color: var(--color-dominant)'>Usiądźmy</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--left mt--0" href={"/pl/kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pl--0 pt--50" name={"stopka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--3 pb--40 pt--40 flex--bottom" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Merbau"}>
              </Title>

              <Text className="text-box text-box--left fs--16" style={{"maxWidth":351}} content={"Produkujemy drewniane meble w centrum Wrocławia z najwyższej jakości naturalnych surowców."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--left" content={"Pańska 18"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"Warszawa, Miasto Warszawa"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"+48 797 811 2X0"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--hvr2 btn-box--sbtn1 btn-box--cColor2 btn-box--filling2 fs--18" href={"#uvod"} content={"<span style=\"color: rgb(255, 255, 255);\">Powrót do góry</span>"}>
              </Button>

              <Text className="text-box text-box--right mt--0" content={"<a href=\"https://saywebpage.com\">Tworzenie stron</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}